import axios from 'axios';

import { setLoader } from './loader';
import { addNotice } from './notices';
import history from '../history';

import {
  SEARCH_RESULTS,
  MORE_SEARCH_RESULTS,
  CLEAR_RESULTS,
  SEARCH_ALIAS,
  ITEMS_RESULTS,
} from './types';

export const search =
  (search, type = 'all', page = 1) =>
  async (dispatch) => {
    dispatch(setLoader('search', true));
    if (page === 1) {
      history.push(`/search/builds/${search}`);
      dispatch({ type: CLEAR_RESULTS, payload: {} });
    }
    const response = await axios.get(`/api/search/`, {
      params: {
        q: search,
        type,
        page,
      },
    });
    if (response.data.error) {
      dispatch(
        addNotice({
          notice: response.data.error,
          type: 'negative',
          time: new Date(),
        })
      );
      dispatch({ type: CLEAR_RESULTS, payload: {} });
      dispatch(setLoader('search', false));
    } else {
      if (page > 1 && type !== 'all') {
        dispatch({
          type: MORE_SEARCH_RESULTS,
          payload: { ...response.data, type, page },
        });
        dispatch(setLoader('search', false));
        return;
      }
      dispatch({
        type: SEARCH_RESULTS,
        payload: { ...response.data, page, term: search },
      });
      dispatch(setLoader('search', false));
      if (type === 'items') {
        history.push(`/search/items/${search}`);
        // window.history.pushState won't redirect to new url
        // window.history.pushState({}, null, `/search/items/${search}`);
      } else if (type === 'lists') {
        history.push(`/search/lists/${search}`);
        // window.history.pushState won't redirect to new url
        // window.history.pushState({}, null, `/search/items/${search}`);
      } else if (response.data['builds'].paginatedBuilds.length > 0) {
        history.push(`/search/builds/${search}`);
        // window.history.pushState({}, null, `/search/builds/${search}`);
      } else if (response.data['items'].paginatedItems.length > 0) {
        history.push(`/search/items/${search}`);
        // update url without reloading
        // window.history.pushState({}, null, `/search/items/${search}`);
      } else if (response.data['athletes'].length > 0) {
        history.push(`/search/profiles/${search}`);
      } else if (response.data['lists'].paginatedLists.length > 0) {
        history.push(`/search/lists/${search}`);
      } else {
        dispatch(
          addNotice({
            notice: 'No results',
            type: 'negative',
            time: new Date(),
          })
        );
      }
    }
  };

export const searchMake = (term) => async (dispatch) => {
  const response = await axios.get('/api/search/makes', { params: { term } });
  dispatch({ type: ITEMS_RESULTS, payload: { makes: response.data } });
};

export const searchDistributor = (term) => async (dispatch) => {
  const response = await axios.get('/api/search/distributors', {
    params: { term },
  });
  dispatch({ type: ITEMS_RESULTS, payload: { distributor: response.data } });
};

export const searchFamily = (term, makeId) => async (dispatch) => {
  const response = await axios.get('/api/search/families', {
    params: { term, makeId },
  });
  dispatch({ type: ITEMS_RESULTS, payload: { family: response.data } });
};

export const searchVariant = (variant, variantType) => async (dispatch) => {
  const response = await axios.get('/api/search/items/variants', {
    params: { variant, variantType },
  });
  dispatch({
    type: ITEMS_RESULTS,
    payload: { variant: { [variantType]: response.data } },
  });
};

export const searchItem = (term) => async (dispatch) => {
  const response = await axios.get('/api/search/items', { params: { term } });
  dispatch({ type: ITEMS_RESULTS, payload: { item: response.data } });
};

export const searchSubjects = (term) => async (dispatch) => {
  const response = await axios.get('/api/search/subjects', {
    params: { term },
  });
  dispatch({ type: ITEMS_RESULTS, payload: { subject: response.data } });
};

export const searchCollections = (id) => async (dispatch) => {
  try {
    const response = await axios.get('/api/search/collections', {
      params: { id },
    });
    dispatch({ type: ITEMS_RESULTS, payload: { collection: response.data } });
  } catch (err) {
    console.log(err);
  }
};

export const searchAlias = (alias) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/search/alias/${alias}`);
    dispatch({ type: SEARCH_ALIAS, payload: response.data });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.message,
        type: 'error',
        time: new Date(),
      })
    );
  }
};

export const searchAthlete =
  (term, profileType = 'athlete') =>
  async (dispatch) => {
    const response = await axios.get('/api/search/athletes', {
      params: { term, profileType },
    });
    dispatch({
      type: ITEMS_RESULTS,
      payload: { [profileType]: response.data },
    });
  };

export const searchProfiles = (term) => async (dispatch) => {
  if (!term) {
    return;
  }
  try {
    const response = await axios.get('/api/search/profiles', {
      params: { term },
    });
    dispatch({ type: ITEMS_RESULTS, payload: { profiles: response.data } });
  } catch (err) {
    dispatch(
      addNotice({
        notice: err.response.data.error,
        type: 'negative',
        time: new Date(),
      })
    );
  }
};

export const searchUsers = (term) => async (dispatch) => {
  const response = await axios.get('/api/search/users', { params: { term } });
  dispatch({ type: ITEMS_RESULTS, payload: { user: response.data } });
};

export const clearSearch =
  (type = 'q') =>
  async (dispatch) => {
    dispatch({ type: ITEMS_RESULTS, payload: { [type]: [] } });
  };

export const getCategories = (search) => async (dispatch) => {
  const response = await axios.get('/api/search/categories', {
    params: { search },
  });
  dispatch({ type: ITEMS_RESULTS, payload: { _category: response.data } });
};

export const searchLocations = (geo, criteria) => async (dispatch) => {
  const response = await axios.get('/api/locations/nearby', {
    params: { geo, ...criteria },
  });
  dispatch({ type: ITEMS_RESULTS, payload: { local: response.data } });
};
