import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import Identity from '../../identity';
import CustomInput from '../../forms/CustomInput';
import Waitlist from '../waitlist';

import RecordEvent from '../../analytics/pageview';

import { connect } from 'react-redux';

import { addToWaitlist } from '../../../actions';

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const RenderImage = ({
  file,
  className,
  position,
  order,
  handleOrderChange,
  hideSmall,
}) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show && order === position) {
      // show after one second
      setTimeout(() => {
        setShow(true);
        handleOrderChange();
      }, 500);
    }
  }, [order, position, show, handleOrderChange]);

  const hideSmallClass = hideSmall ? 'hidden sm:block' : '';

  return (
    <img
      src={`/splash/${file}`}
      alt=""
      className={`${
        loading || !show ? 'invisible' : 'visible animate-fade-in'
      } ${className} ${hideSmallClass} object-scale-down`}
      onLoad={() => setLoading(false)}
    />
  );
};

const Splash = ({ addToWaitlist }) => {
  const [waitlist, setWaitlist] = useState('');
  const [order, setOrder] = useState(1);

  const onSubmit = async (formValues) => {
    await addToWaitlist(formValues);
    setWaitlist(formValues.email);
  };

  function handleOrderChange() {
    setOrder(order + 1);
  }

  const renderForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        validate={(formValues) => {
          const errors = {};
          if (formValues.email && !validateEmail(formValues.email)) {
            errors.email = 'Please enter a valid email address';
          }
          return errors;
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="ui form error">
            <div className="flex flex-col sm:flex-row items-start justify-center gap-0">
              <div className="flex flex-col items-center justify-center gap-0 w-64">
                <Field
                  name="email"
                  component={CustomInput}
                  placeholder="Enter your email address"
                  className="border border-white rounded-none shadow-sm focus:outline-none focus:ring-white focus:border-white dark:focus:ring-yellow-700 dark:bg-white dark:text-black"
                />
              </div>
              <button
                className="h-12 w-64 p-2 bg-black text-white shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 disabled:opacity-75"
                type="submit"
                disabled={submitting || pristine}
              >
                Request Early Access
              </button>
            </div>
          </form>
        )}
      />
    );
  };

  if (waitlist) {
    return (
      <>
        <Waitlist email={waitlist} onClickHandler={setWaitlist} />
      </>
    );
  }

  return (
    <>
      <div className="w-full items-center justify-center flex flex-col pb-14 -mb-14 bg-gradient-to-b from-yellow-300 to-yellow-200">
        <div className="container w-screen  h-screen items-center flex flex-col -mb-14 justify-center overflow-hidden">
          <div className="flex-none grid grid-cols-4 grid-rows-1 text-center gap-4">
            <div className="self-start col-span-2 row-start-1 col-start-1 sm:col-start-1 translate-x-10 sm:translate-y-0 xl:translate-x-20">
              <RenderImage
                file="rapha-el.png"
                className="rotate-180"
                position={2}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
            <div className="self-center row-start-1 col-start-1 transform scale-75 lg:scale-50 invisible sm:visible sm:-rotate-6 translate-y-24 sm:translate-y-52 translate-x-12 sm:translate-x-5 md:translate-x-8">
              <RenderImage
                file="bidon.png"
                className=""
                hideSmall
                position={6}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
            <div className="self-center -translate-y-2 translate-x-24 sm:translate-x-8 sm:translate-y-20 row-start-1 col-start-2 col-span-2 sm:col-start-3">
              <RenderImage
                file="enve-wheel.png"
                className=""
                position={4}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
          </div>
          <div className="flex-grow grid grid-cols-1 z-10 self-center dark:text-black animate-fade-in">
            <div className="flex flex-col items-center justify-center px-5 gap-4">
              <Identity noDark />
              <h1 className="text-4xl uppercase identity whitespace-nowrap tracking-wide">
                Active Projects
              </h1>
              <h2 className="text-2xl font-serif font-extralight text-center">
                <span className="italic">
                  Inspiring, equipping, & connecting
                </span>{' '}
                modern cyclists.
              </h2>
              {renderForm()}
              <div className="flex flex-row gap-2">
                <Link
                  className="underline underline-offset-8 backdrop-blur-sm"
                  to="/login"
                >
                  Sign in
                </Link>
                <span className="text-black">or</span>
                <Link
                  className="underline underline-offset-8 backdrop-blur-sm"
                  to="/feed"
                >
                  Continue without signing in
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-none grid grid-cols-4 grid-rows-1 text-center gap-4 ">
            <div className="self-start row-start-1 col-start-1 scale-150 sm:scale-100 translate-x-14 sm:translate-x-4 translate-y-4 sm:-translate-y-4 lg:-translate-y-32 xl:-translate-y-40 -rotate-6">
              <RenderImage
                file="chromabib.png"
                className=""
                position={3}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
            <div className="self-center row-start-1 col-start-3 sm:col-start-2 col-span-2 sm:col-span-2 transform scale-75 -translate-y-4 sm:-translate-y-4 xl:-translate-y-18 h-fit">
              <RenderImage
                file="build.png"
                className=""
                position={1}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
            <div className="self-start invisible sm:visible row-start-1 col-start-4 -translate-x-4 sm:translate-y-14 scale-150 sm:-rotate-45">
              <RenderImage
                file="outershellframebag.png"
                className=""
                hideSmall
                position={5}
                order={order}
                handleOrderChange={handleOrderChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* position bottom center of page */}
      <div className="fixed bottom-0 w-full flex justify-center p-4">
        <Link to="/directory">
          <button className="bg-yellow-300 text-black p-2 rounded-lg shadow-sm focus:outline-none focus:ring-amber-500 focus:border-amber-500 btn-sm text-xs">
            View Directory
          </button>
        </Link>
      </div>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title="Active Projects - Splash Page"
      />
    </>
  );
};

export default connect(null, { addToWaitlist })(Splash);
