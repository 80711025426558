import _ from 'lodash';

import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import history from '../../history';

import { connect } from 'react-redux';

import Masonry from 'react-masonry-css';

import Header from '../header';
import Page from '../ui/Page';
import BuildCard from '../builds/card';
import Loader from '../Loader';
import GlassUp from '../landing/glassup';

import BuildsFilter from '../filters/builds';

import RecordEvent from '../analytics/pageview';

import Helmet from '../ui/Helmet';

import {
  getLatestBuilds,
  getFollowedBuilds,
  getFeaturedBuilds,
} from '../../actions';

const Feed = ({
  getLatestBuilds,
  getFollowedBuilds,
  getFeaturedBuilds,
  builds,
  lists,
  buildsType,
  buildsParams,
  totalPages,
  loading,
  isSignedIn,
}) => {
  // set state for feed type
  const [mount, setMount] = React.useState(false);
  const feedType = useParams().feedType || 'featured';
  const [feed, setFeed] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const location = useLocation();
  const allParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  // convert allParams to string, eg "type gravel, profile midsouth"
  const allParamsString = Array.from(allParams.entries())
    .map((param) => `${param[1]}`)
    .join(', ');
  const allParamsStringified = allParamsString ? `${allParamsString}` : '';

  // page title from allParamsStringified and feedType capitalized
  const pageTitle = `${feedType} ${
    allParamsStringified ? allParamsStringified : ''
  }`;

  React.useEffect(() => {
    const allSearchParams = Array.from(allParams.entries());
    const params = {};
    // Iterate over all search parameters and add them to the params object
    for (let param of allSearchParams) {
      params[param[0]] = param[1];
    }

    if (
      (!mount &&
        (buildsType !== feedType || !_.isEqual(params, buildsParams))) ||
      (!loading &&
        (buildsType !== feedType || !_.isEqual(params, buildsParams)))
    ) {
      setPage(1);
      setMount(true);
      switch (feedType) {
        case 'following':
          getFollowedBuilds({ params });
          setFeed('following');
          break;
        case 'latest':
          getLatestBuilds({ params });
          setFeed('latest');
          break;
        case 'featured':
          getFeaturedBuilds({ params });
          setFeed('featured');
          break;
        default:
          // change url to /feed
          history.push('/feed');

          getFeaturedBuilds({ params });
          setFeed('featured');
          break;
      }
    }
  }, [
    feedType,
    feed,
    mount,
    allParams,
    loading,
    buildsType,
    buildsParams,
    getLatestBuilds,
    getFollowedBuilds,
    getFeaturedBuilds,
  ]);

  // get the next page of builds if the user scrolls to the bottom of the page
  React.useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      // if we're on the last page or more results loading, don't bug the server
      if (page === totalPages || loading) return;

      // get next page of builds
      if (feedType === 'latest') {
        getLatestBuilds({ page: page + 1, params: buildsParams });
      } else if (feedType === 'following') {
        getFollowedBuilds({ page: page + 1, params: buildsParams });
      } else if (feedType === 'featured') {
        getFeaturedBuilds({ page: page + 1, params: buildsParams });
      } else if (page !== totalPages) {
        getFeaturedBuilds({ page: page + 1, params: buildsParams });
      }
      // increment page
      setPage(page + 1);
    }
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [
    feedType,
    page,
    setPage,
    totalPages,
    buildsParams,
    loading,
    getFollowedBuilds,
    getLatestBuilds,
    getFeaturedBuilds,
  ]);

  const allSearchParams = Array.from(allParams.entries());
  const params = {};
  // Iterate over all search parameters and add them to the params object
  for (let param of allSearchParams) {
    params[param[0]] = param[1];
  }

  function renderNoFollowingMsg() {
    return (
      <div className="flex justify-center items-center h-[65vh]">
        <div>
          <h1>You are ahead of the pack. Following no one.</h1>
          <h2>That's impressive, but makes the road a little lonely.</h2>
          <p>
            Follow the athletes you want to get behind and we'll keep you up to
            speed here.
          </p>
          <Link to="/feed" className="btn btn-primary my-4">
            Find Someone
          </Link>
        </div>
      </div>
    );
  }

  function renderBuilds() {
    if (!loading && totalPages === 0) {
      return renderNoFollowingMsg();
    }

    return (
      <>
        <Masonry
          breakpointCols={{ 640: 2, 1280: 3, default: 5 }}
          className="masonry-grid gap-4 md:gap-8 lg:gap-8 px-4 md:px-0"
          columnClassName="masonry-grid_column"
        >
          {builds.map((build, i) => {
            return (
              <BuildCard
                key={build._id}
                index={i}
                build={build}
                noCarousel={true}
                lists={lists}
                noReactions={true}
                noAvatar={true}
                location="feed"
              />
            );
          })}
        </Masonry>
      </>
    );
  }

  const shortcuts = [
    {
      label: 'Featured',
      value: `feed${params ? `?${new URLSearchParams(params)}` : ''}`,
      active: feedType === 'featured' || feedType === 'all',
    },
    {
      label: 'Following',
      value: `feed/following${params ? `?${new URLSearchParams(params)}` : ''}`,
      active: feedType === 'following',
      disabled: !isSignedIn,
    },
    {
      label: 'Latest',
      value: `feed/latest${params ? `?${new URLSearchParams(params)}` : ''}`,
      active: feedType === 'latest',
    },
    {
      label: 'Gravel',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?type=gravel`,
      active: params.type === 'gravel',
    },
    {
      label: 'Road',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?type=road`,
      active: params.type === 'road',
    },
    {
      label: 'Cargo',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?type=cargo`,
      active: params.type === 'cargo',
    },
    {
      label: 'Bikepacking',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?type=bikepacking`,
      active: params.type === 'bikepacking',
    },
    {
      label: 'The Mid South',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=midsouth`,
      active: params.profile === 'midsouth',
    },
    {
      label: 'MADE',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=made`,
      active: params.profile === 'made',
    },
    {
      label: 'The Radavist',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=theradavist`,
      active: params.profile === 'theradavist',
    },
    {
      label: 'The Service Course',
      value: `${
        feedType ? `feed/${feedType}` : 'feed'
      }?profile=theservicecourse`,
      active: params.profile === 'theservicecourse',
    },
    {
      label: 'True Love',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=truelove`,
      active: params.profile === 'truelove',
    },
    {
      label: 'The Meteor - Austin',
      value: `${
        feedType ? `feed/${feedType}` : 'feed'
      }?profile=themeteoraustin`,
      active: params.profile === 'themeteoraustin',
    },
    {
      label: 'Big Sugar Classic',
      value: `${
        feedType ? `feed/${feedType}` : 'feed'
      }?profile=bigsugarclassic`,
      active: params.profile === 'bigsugarclassic',
    },
    {
      label: 'The Sunday Chug',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=sundaychug`,
      active: params.profile === 'sundaychug',
    },
    {
      label: 'Tour Divide',
      value: `${feedType ? `feed/${feedType}` : 'feed'}?profile=tourdivide`,
      active: params.profile === 'tourdivide',
    },
  ];

  return (
    <Page>
      <Helmet
        title={`Active Projects Feed - ${pageTitle} bike builds`}
        description="Discover new builds and follow your favorite athletes on Active Projects"
        url={`${process.env.REACT_APP_URL}/feed`}
      />
      <Header />
      <GlassUp required bottom>
        <div>
          <div className="tabs flex justify-center mt-6 mb-2 whitespace-nowrap">
            <Link
              className={`tab text-xl`}
              to={`/discover`}
              disabled={!isSignedIn}
            >
              Discover
            </Link>
            <Link className={`tab text-xl tab-active font-bold`}>Builds</Link>
          </div>
        </div>
        <div className="pt-4">
          <BuildsFilter shortcuts={shortcuts} setFilters={() => {}} />
        </div>
        {renderBuilds()}
        {loading && <Loader type="builds" />}
        <RecordEvent
          hitType="pageview"
          page={window.location.pathname}
          title={`Feed - ${pageTitle}`}
        />
      </GlassUp>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const athlete =
    state.athlete && state.athlete[state.auth.athlete]
      ? state.athlete[state.auth.athlete]
      : {
          name: state.auth.name,
          email: state.auth.email,
          id: '_new_',
        };

  const builds =
    state.builds && state.builds.builds
      ? Object.values(state.builds.builds)
      : [];
  const totalPages =
    state.builds && state.builds.totalPages ? state.builds.totalPages : 0;
  const loading =
    state.loader && state.loader.builds ? state.loader.builds : false;
  const buildsType =
    state.builds && state.builds.type ? state.builds.type : null;
  const buildsParams =
    state.builds && state.builds.params ? state.builds.params : null;

  const isSignedIn = state.auth?.userId;

  return {
    auth: state.auth,
    athlete,
    builds,
    buildsType,
    buildsParams,
    totalPages,
    isSignedIn,
    loading,
  };
};

export default connect(mapStateToProps, {
  getLatestBuilds,
  getFollowedBuilds,
  getFeaturedBuilds,
})(Feed);
